import styled from '@emotion/styled';
import { ChevronUpIcon } from '../assets/icons';

interface FloatingUpButtonProps {
  scrollContainerRef: React.RefObject<HTMLElement>;
}

export default function FloatingUpButton({
  scrollContainerRef,
}: FloatingUpButtonProps) {
  return (
    <FloatingButton
      onClick={() =>
        scrollContainerRef.current?.scrollTo({
          top: 0,
          behavior: 'smooth',
        })
      }
    >
      <ChevronUpIcon width={24} height={24} />
    </FloatingButton>
  );
}

const FloatingButton = styled.button`
  position: fixed;
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  bottom: 3.75rem;
  right: 3.75rem;
  background-color: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);

  display: flex;
  align-items: center;
  justify-content: center;
`;
