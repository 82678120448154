import { css } from '@emotion/react';

import { BackIcon } from '../assets/icons';

import styled from '@emotion/styled';
import { displaySmall } from '../styles';

import { useLocation } from '@reach/router';
import { goPreviousPage } from '../utils/route';

interface NavigationBar {
  children: React.ReactNode;
  rightButton?: React.ReactNode;
}

export default function NavigationBar({
  children,
  rightButton,
}: NavigationBar) {
  const location = useLocation();

  return (
    <nav css={navigationBarStyle}>
      <div css={navigationContainerStyle}>
        <div
          css={css`
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          `}
        >
          <header css={[displaySmall]}>{children}</header>
        </div>
        <IconButton
          onClick={() => {
            goPreviousPage(location);
          }}
          aria-label="back button"
          // css={css`
          //   margin-left: -1.25rem;
          // `}
        >
          <BackIcon />
        </IconButton>
        {rightButton}
      </div>
    </nav>
  );
}

const IconButton = styled.button`
  > svg {
    width: var(--xlarge2);
    height: var(--xlarge2);
  }
`;

const navigationBarStyle = css`
  flex: 0 0 auto;
  background: white;

  display: block;
  padding-top: env(safe-area-inset-top);
  border-bottom: 1px solid #ededed;
`;

const navigationContainerStyle = css`
  display: flex;
  padding: 1.125rem var(--large);
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;
