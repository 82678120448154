import { css } from '@emotion/react';
import { ImageEmptyState } from '../assets/images';

export default function EmptyGameList(
  props: React.ComponentPropsWithoutRef<'div'>
) {
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 1;
      `}
      {...props}
    >
      <ImageEmptyState width="10rem" />
      <span
        css={css`
          font-weight: 400;
          font-size: 1.875rem;
          line-height: var(--xlarge2);
          color: #a9a9a9;
          margin-top: var(--xlarge2);
        `}
      >
        아직 만든 게임이 없어요
      </span>
    </div>
  );
}
